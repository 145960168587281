const local = {
  s3: {
    // BUCKET: 'https://omrsheet.s3.ap-south-1.amazonaws.com/',
    // ERP_BUCKET: 'https://erp-revamp.s3.ap-south-1.amazonaws.com/',
    BUCKET: 'https://d2r9gkgplfhsr2.cloudfront.net/',
    ERP_BUCKET: 'https://d3ka3pry54wyko.cloudfront.net/',
  },
  apiGateway: {
    // baseURL: 'http://localhost:8000/qbox',
    baseURL: 'https://dev.mgmt.letseduvate.com/qbox',
    xAPIKey: 'vikash@12345#1231',
  },
};

const dev = {
  s3: {
    BUCKET: 'https://d2r9gkgplfhsr2.cloudfront.net/',
    ERP_BUCKET: 'https://d3ka3pry54wyko.cloudfront.net/',
    // BUCKET: 'https://omrsheet.s3.ap-south-1.amazonaws.com/',
    // ERP_BUCKET: 'https://erp-revamp.s3.ap-south-1.amazonaws.com/',
  },
  apiGateway: {
    baseURL: 'https://mgmt.dev-k8.letseduvate.com/qbox',
    // baseURL: 'http://192.168.0.67:8000/qbox',
    xAPIKey: 'vikash@12345#1231',
  },
};
const qa = {
  s3: {
    // BUCKET: 'https://omrsheet.s3.ap-south-1.amazonaws.com/',
    // ERP_BUCKET: 'https://erp-revamp.s3.ap-south-1.amazonaws.com/',
    BUCKET: 'https://d2r9gkgplfhsr2.cloudfront.net/',
    ERP_BUCKET: 'https://d2r9gkgplfhsr2.cloudfront.net/',
  },
  apiGateway: {
    baseURL: 'https://mgmt.qa.letseduvate.com/qbox',
    // baseURL: 'http://192.168.0.67:8000/qbox',
    // baseURL: 'http://127.0.0.1:8000//qbox',
    xAPIKey: 'vikash@12345#1231',
  },
};
const stage = {
  s3: {
    // BUCKET: 'https://omrsheet.s3.ap-south-1.amazonaws.com/',
    // ERP_BUCKET: 'https://omrsheet.s3.ap-south-1.amazonaws.com/',
    BUCKET: 'https://mgmt-cdn-stage.stage-gke.letseduvate.com/',
    ERP_BUCKET: 'https://mgmt-cdn-stage.stage-gke.letseduvate.com/',
    Ibook_CDN: 'https://acad-cdn-stage.stage-vm.letseduvate.com/',
  },
  apiGateway: {
    baseURL: 'https://mgmt-stage.stage-gke.letseduvate.com/qbox',
    xAPIKey: 'vikash@12345#1231',
  },
};

const prod = {
  s3: {
    // BUCKET: 'https://omrsheet.s3.ap-south-1.amazonaws.com/',
    // ERP_BUCKET: 'https://omrsheet.s3.ap-south-1.amazonaws.com/',
    BUCKET: 'https://mgmt-cdn.letseduvate.com/',
    ERP_BUCKET: 'https://mgmt-cdn.letseduvate.com/',
    Ibook_CDN: 'https://acad-cdn.letseduvate.com/',
  },
  apiGateway: {
    baseURL: `${window.location.origin}/qbox`,
    xAPIKey: 'vikash@12345#1231',
  },
};

const PROD = 'PROD';
const DEV = 'DEV';
const QA = 'QA';
const STAGE = 'STAGE';
const env = { [PROD]: prod, [DEV]: dev, [QA]: qa, [STAGE]: stage };
const config = env[process.env.REACT_APP_UI_ENV] || stage;
export default {
  // Add common config values here
  TINYMCE_API_KEY: 'hdyjhhciiey3ac2fnv2vo4ogpeiyio8s2ojfk55dw52vy94e',
  MAX_ATTACHMENT_SIZE: 5000000,
  isCentral: true,
  ...config,
};
